import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import i18n from './../i18n'
import colors from 'vuetify/es5/util/colors'
import '../assets/icons/style.css'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#FF66BF',
        secondary: colors.blueGrey.base,
        _accent: '#050015', // colors.deepPurple.base,
        _error: colors.red.base,
        _warning: colors.amber.base,
        _info: colors.lightBlue.base,
        _success: colors.green.base,
      },
    },
  },
  icons: {
    assistedSuggest: 'as',
  },

  // pass through i18n
  lang: {
    t: (key, ...params) => i18n.t(key, params),
  },
})
